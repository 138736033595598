(function ($) {
  "use strict";

  if (!localStorage.getItem('appliedCookies')) {
    $('#disclaimer').css('display', 'block');
    localStorage.setItem('appliedCookies', true);
  }
  $('#disclaimer .close').click(function() { $('#disclaimer').css('display', 'none'); });

  $(document).on('click', '#treaty-request', function(e){
    var error = false;
    var data;
    $('.form-message').text('');

    if(!$('.js-name-mask').inputmask('isComplete') || !$('.js-phone-mask').inputmask('isComplete') || !$('.js-email-mask').inputmask('isComplete')){
      error = true;
    }

    if(!$.trim($('[name=client-treaty]').val()).length || !$.trim($('[name=client-email]').val()).length){
      error = true;
    }

    if(!error){
      data = {
        name: $('[name=client-name]').val(),
        phone: $('[name=client-phone]').val(),
        email: $('[name=client-email]').val(),
        treaty: $('[name=client-treaty]').val(),
        domain: $('.treaty__plashka').text()
      };

      $.ajax({
        url: '/services/treaty_request',
        type: 'post',
        data: data,
        beforeSend: function(){
          $('#treaty-request').addClass('processing')
        },
        success: function(response){
          $('#treaty-request').removeClass('processing');

          if(response.result.data.result === 'success'){
            $('#treaty-request').prop('disabled', true);
            $('.form-message').addClass('form-message_success').text('Спасибо! Вскоре мы свяжемся с вами.');
          } else {
            $('.form-message').text('Произошла ошибка. Повторите еще раз.');
          }
        }
      })
    } else {
      $('.form-message').text('Заполните все поля!');
    }
  });

  /**
   * Счетчик в таблицах с ssl
   */
  (function () {
    function moreAndLess(className, val, input, max, min) {
      if (className === 'less' && val > +min) {
        val--;
      }
      if (className === 'more' && val < +max) {
        val++;
      }
      return +val;
    }

    function theEntireCost(count, cost) {
      return +cost;
    }

    $('body').on('click', '.less, .more', function (e) {
      var $this = $(this),
        className = $this.attr('class'),
        input = $this.parent('td').find('input[name=\'count\']'),
        price = $this.closest('tr').find('.js-price-per-unit'),
        priceCount = price.data('price-per-1'),
        priceCount2 = price.data('price-per-2'),
        priceCount3 = price.data('price-per-3'),
        val = input.val(),
        max = input.data('max'),
        min = input.data('min');
      var returnMoreAndLess = moreAndLess(className, val, input, max, min),
        priceVal;
      input.val(returnMoreAndLess);
      if (returnMoreAndLess == 1) {
        priceVal = priceCount;
      } else if (returnMoreAndLess == 2) {
        priceVal = priceCount2;
      } else if (returnMoreAndLess == 3) {
        priceVal = priceCount3;
      }
      var resultTheEntireCost = theEntireCost(returnMoreAndLess, priceVal);
      price.html(resultTheEntireCost);

    });


  })();

  /**
   * fix modals bootstrap
   */
  (function () {
    var scrollBarWidth = 0;
    (function () {
      var a = document.createElement("div");
      a.className = 'modal-scrollbar-measure';
      document.body.appendChild(a);
      scrollBarWidth = a.offsetWidth - a.clientWidth;
      document.body.removeChild(a);
    })();

    $(document).on('show.bs.modal', '.modal', function () {
      $('.navbar.navbar-default').css('padding-right', scrollBarWidth);
      $('footer').css('padding-right', scrollBarWidth);
      $('.modal').css('overflow', 'auto');
    }).on('hide.bs.modal', '.modal', function () {
      $('.navbar.navbar-default').css('padding-right', 0);
      $('footer').css('padding-right', 0);
      $('.modal').css('overflow', 'hidden');
    });
  })();

  (function () {
    $('body').on('click', '.js-popup-ssl-show', function (e) {
      e.preventDefault();
      var $this = $(this),
        nameSll = $this.closest('tr').find('.js-name-ssl').html(),
        titleSll = $('.modal-content .modal-ssl__title');

      titleSll.html('Вы выбрали ' + nameSll);
    });

    $('body').on('shown.bs.modal', '.modal.modal-ssl', function (e) {
      var input = $('input[type="text"]', this);
      input.focus();
    });
  })();

  /*
   * Эффект добавления в корзину
   * */
  $('body').on('click', '.domain-ssl__table tr', function () {
    $(this).toggleClass('select');
  });

  $(function () {

    // slider-reckl
    $('.slider.slider-reckl').slick({
      dots: true,
      infinite: false,
      speed: 300,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 606,
          settings: {
            dots: false,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    //slider en index, domains, slider-reckl
    $('.slider.slider-domain').slick({
      dots: true,
      infinite: false,
      speed: 300,
      arrows: false,
      slidesToShow: 5,
      slidesToScroll: 5,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 606,
          settings: {
            dots: false,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    $('.slider.marketing').slick({
      dots: true,
      infinite: true,
      arrows: false,
      autoplay: true,
      autoSpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            dots: false,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    //slider business-decisions, slider info
    $('.slider.business-decision').slick({
      dots: true,
      infinite: false,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            dots: false,
            arrows: true,
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 606,
          settings: {
            dots: false,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    $('.banner-slick--slider').slick({
      arrows: false,
      speed: 1000,
      autoplaySpeed: 5000,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      accessibility: false,
      draggable: false,
      swipe: false,
      // rtl: true,
    });

    //Banner register
    $('.banner-slick--register .banner-slick--slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      var arrZones = ['photo','fashion','cafe'];
      $('.banner-slick--register .capsule .zone-div__zone').text('.' + arrZones[nextSlide]);
    });
    //Banner index
    $('.banner-slick--index .banner-slick--slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      $('[data-capsule]').removeClass('active');
      $('[data-capsule='+nextSlide+']').addClass('active');
    });
  });
  //table-main

  // domains search
  $(function () {
    if ($(window).width() > 900) {
      $('.top-search  > .domain-selection')
        .css({
          width: '100%',
          zIndex: '20',
          background: '#eef0f1'
        })
        .sticky({topSpacing: 93});
    }
  });

  //table-domain
  $(function () {
    $('.table-filter > a').on('click', function () {
      $(this).next('.block-filter').slideToggle(300);
      $(this).find(".caret").toggleClass('turn');
    });
    $('a.table-toggle').on('click', function () {
      var cont = $(this).find('i.control-element');
      cont.toggleClass('active');
      /*for time*/
    });
  });
  //accordion
  $(function () {
    $('a.panel-toggle').on('click', function () {
      var cont = $(this).find('i.control-element');
      cont.toggleClass('active');
      /*for time*/
      $(this).toggleClass('active');
    });
  });
  //spoilers
  $(function () {
    $("a.href-spoiler").click(function () {

      if ($(this).hasClass('current')) {

        $(this).removeClass('current').next().slideUp();
      }
      else {

        $('a.href-spoiler.current').removeClass('current').next().slideUp();

        $(this).addClass('current').next().slideDown();
      }
    });
  });
  //accordion-spoilers in EDS page
  $(function () {
    $(".thead-tariffs").click(function () {

      if ($(this).hasClass('current')) {

        $(this).removeClass('current').next().slideUp();
      }
      else {

        $('.thead-tariffs.current').removeClass('current').next().slideUp();

        $(this).addClass('current').next().slideDown();
      }
    });
  });

  $('.panel .panel-toggle').mousedown(function (e) {

    e.preventDefault();
  });

  //Mask for input
  $('.js-name-mask').inputmask({
    mask: '*{3,20}',
    definitions: {
      '*': {
        validator: "[A-Za-zА-Яа-я]",
      }
    }
  });
  $('.js-phone-mask').inputmask({'mask': '+7 (999) 999-99-99', 'placeholder': '_'});
  $('.js-email-mask').inputmask({
    mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
    onBeforePaste: function (pastedValue, opts) {
      pastedValue = pastedValue.toLowerCase();
      return pastedValue.replace("mailto:", "");
    },
    definitions: {
      '*': {
        validator: "[0-9A-Za-zА-Яа-я!#$%&'*+/=?^_`{|}~\-]",
        casing: "lower"
      }
    }
  });

  //for recaptcha
  window.recaptchaCallback = function(){
    $('#hiddenRecaptcha').valid();
  };

  //Modal
  $('.modal#modal_standard [type="submit"]').click(function(e){
    if(!$('.js-name-mask').inputmask('isComplete') || !$('.js-phone-mask').inputmask('isComplete')){
      $(this).parents('.modal').find('.form-message').text('Заполните поля имя и телефон!');
      e.preventDefault();
    }

    if($('.js-email-mask').val().length != 0){
      if(!$('.js-email-mask').inputmask('isComplete')){
        $(this).parents('.modal').find('.form-message').text('Неверно заполнено поле Email');
        e.preventDefault();
      }
    }
  });

  /**
   * form-signin
   */
  $('#form-signin').validate({
    errorClass: 'error',
    rules: {
      'username': 'required',
      'password': 'required'
    },
    messages: {
      'username': 'Обязательно для заполнения',
      'password': 'Обязательно для заполнения'
    },
    showErrors: function (errorMap, errorList) {
      $.each(this.successList, function (index, value) {
        $(value).removeClass('error');
      });
      $.each(errorList, function (index, value) {
        //value.message
        $(value.element).addClass('error');
      });
    }
  });

  /**
   * domains filter on "/services/partnership" page
   */
  (function () {
    var $tTable = $('#tariff-list'),
      $trs = $tTable.find('tr[data-zone]'),
      $showAll = $('#show-tariffs');

    $('#filter-form-tariff').on('submit', function () {
      var zone = $(this).find('input').val();
      if (!zone) {
        return false;
      }

      $trs.addClass('hidden').each(function () {
        if ($(this).data('zone').indexOf(zone.toLowerCase()) !== -1) {
          $(this).removeClass('hidden');
          return true;
        }
      });
      $showAll.removeClass('hidden');

      return false;
    });

    $showAll.on('click', function () {
      $trs.removeClass('hidden');
      $(this).addClass('hidden');
    });
  })();

  /**
   * popup form on "/services/special-partnership" page
   */
  (function () {
    if (window.location.pathname !== '/services/special-partnership') return;

    $('body').append(
      '<div class="modal admin-modal fade in" id="modal_partnership" tabindex="-1" role="dialog" aria-hidden="true">\
          <div class="modal-dialog">\
              <div class="modal-content">\
                  <div class="modal-header">\
                      <button type="button" class="close" data-dismiss="modal" aria-label="close">\
                          <span aria-hidden="true"><img src="/images/icons/close.png" alt="x"/></span>\
                      </button>\
                      <div class="modal-title">Отправить заявку</div>\
                  </div>\
                  <form class="form-horizontal order-form" method="post" id="form-partnership"\
                        action="/services/partnership-request">\
                      <div class="modal-body">\
                          <div class="text-center">\
                              <div class="form-group">\
                                  <label for="yourname"></label>\
                                  <input type="text" class="form-control" name="yourname"\
                                         placeholder="Ваше имя"\
                                         id="yourname">\
                              </div>\
                              <div class="form-group">\
                                  <label for="email"></label>\
                                  <input type="text" class="form-control" name="email"\
                                         placeholder="Ваш email" id="email">\
                              </div>\
                              <div class="form-group">\
                                  <label for="client-phone"></label>\
                                  <input type="tel" class="form-control" name="client-phone"\
                                         placeholder="Ваш телефон" id="client-phone">\
                              </div>\
                              <div class="form-group">\
                                  <label for="quantity-domains"></label>\
                                  <input type="number" class="form-control" name="quantity-domains"\
                                         placeholder="Сколько у вас доменов?" id="quantity-domains" min="0">\
                              </div>\
                              <div class="form-group">\
                                  <label for="comments"></label>\
                                  <textarea class="form-control" rows="6" name="text" id="comments"\
                                            placeholder="Ваши комментарии"></textarea>\
                              </div>\
                              <div class="form-group form-message">\
                              </div>\
                          </div>\
                      </div>\
                      <div class="modal-footer">\
                          <div class="row">\
                              <div class="col-md-12 col-sm-12 col-xs-12">\
                                  <button type="submit" class="btn btn-default btn-block">Отправить</button>\
                              </div>\
                          </div>\
                      </div>\
                  </form>\
              </div>\
          </div>\
      </div>'
    );

    var $alertTemplate = $('<div style="margin: 0 auto -30px; max-width: 320px" class="alert alert-warning alert-dismissible" role="alert">' +
      '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>' +
      '<p></p>' +
      '</div>');

    $('#form-partnership').validate({
      rules: {
        'email': {'email': true},
        'client-phone': {'required': true},
        'quantity-domains': {'digits': true, 'min': 0}
      },
      messages: {
        'client-phone': 'Обязательно для заполнения',
        'email': 'Введите корректный email',
        'quantity-domains': 'Введите корректное число доменов'
      },
      showErrors: function (errorMap, errorList) {
        $.each(this.successList, function (index, value) {
          $(value).removeClass('error');
          $(value).parent().find('.form-control-error').remove();
        });

        return $.each(errorList, function (index, value) {
          var $el = $(value.element);
          $el.addClass('error');
          var msg = $el.parent().find('.form-control-error');
          if (msg.length) {
            msg.html(value.message);
          } else {
            $el.after('<div style="margin-top: 10px;" class="form-control-error">' + value.message + '</div>');
          }
        });
      },
      submitHandler: function (form) {
        var $form = $(form);
        var data = $form.serialize();

        $.post($form.attr('action'), data)
          .done(function (res) {
            var $alert = $form.find('.form-message');
            if (res.result.errors.message) {
              $alertTemplate.find('p').text(res.result.errors.message);
            } else {
              try {fbq('track', 'Lead',{
                content_name: 'form-partnership',
                content_category: 'form'
              })} catch (e) {console.warn(e)}
              $alertTemplate.find('p').text('Спасибо за Ваше обращение. В ближайшее время мы свяжемся с Вами!');
              $form.find('input, textarea').attr('disabled', true);
              setTimeout(function () {
                $('#modal_partnership').find('.close').click();
              }, 7000);
            }
            $alert.html($alertTemplate);
          });
        return false;
      }
    });

  })();

  /**
   * standard-request form
   */
  (function () {
    if (window.location.pathname !== '/services/colocation' && window.location.pathname !== '/services/dedicated') return;

    $('body').append(
      '<div class="modal admin-modal fade in" id="modal_standard" tabindex="-1" role="dialog" aria-hidden="true">\
          <div class="modal-dialog">\
              <div class="modal-content">\
                  <div class="modal-header">\
                      <button type="button" class="close" data-dismiss="modal" aria-label="close">\
                          <span aria-hidden="true"><img src="/images/icons/close.png" alt="x"/></span>\
                      </button>\
                      <div class="modal-title">Запросить конфигурацию сервера</div>\
                  </div>\
                  <form class="form-horizontal order-form" method="post" id="form-standard"\
                        action="/services/standard-request">\
                      <div class="modal-body">\
                          <div class="text-center">\
                              <div class="form-group">\
                                  <label for="yourname"></label>\
                                  <input type="text" class="form-control js-name-mask" name="yourname"\
                                         placeholder="Ваше имя"\
                                         id="yourname">\
                              </div>\
                              <div class="form-group">\
                                  <label for="client-phone"></label>\
                                  <input type="tel" class="form-control js-phone-mask" name="client-phone"\
                                         placeholder="Ваш телефон" id="client-phone">\
                              </div>\
                              <div class="form-group">\
                                  <label for="client-email"></label>\
                                  <input class="form-control js-email-mask" name="client-email"\
                                         placeholder="Ваш email" id="client-email">\
                              </div>\
                              <div class="form-group">\
                                  <label for="client-comments"></label>\
                                  <textarea class="form-control" name="client-comments"\
                                            placeholder="Ваш комментарий" id="client-comments"></textarea>\
                              </div>\
                              <div class="form-group">\
                                  <div class="g-recaptcha" id="g-recaptcha"></div>\
                              </div>\
                              <input type="hidden" name="add_info" class="add-info-input" id="add-info-input">\
                              <input type="hidden" name="mail_title" class="mail-title-input">\
                              <input type="hidden" name="additional_info" class="additional-info-input">\
                              <div class="form-group form-message" style="color: #d42229">\
                              </div>\
                          </div>\
                      </div>\
                      <div class="modal-footer">\
                          <div class="row">\
                              <div class="col-md-12 col-sm-12 col-xs-12">\
                                  <button type="submit" class="btn btn-default btn-block">Отправить</button>\
                              </div>\
                          </div>\
                      </div>\
                  </form>\
              </div>\
          </div>\
      </div>'
    );

    var $alertTemplate = $('<div style="margin: 0 auto -30px; max-width: 320px" class="alert alert-warning alert-dismissible" role="alert">' +
      '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
      '<span aria-hidden="true">×</span>' +
      '</button>' +
      '<p></p>' +
      '</div>');

    //masks for inputs
    $('.js-name-mask').inputmask({
      mask: '*{3,20}',
      definitions: {
        '*': {
          validator: "[A-Za-zА-Яа-я]",
        }
      }
    });
    $('.js-phone-mask').inputmask({'mask': '+7 (999) 999-99-99', 'placeholder': '_'});
    $('.js-email-mask').inputmask({
      mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
      onBeforePaste: function (pastedValue, opts) {
        pastedValue = pastedValue.toLowerCase();
        return pastedValue.replace("mailto:", "");
      },
      definitions: {
        '*': {
          validator: "[0-9A-Za-zА-Яа-я!#$%&'*+/=?^_`{|}~\-]",
          casing: "lower"
        }
      }
    });

    //recaptcha render
    setTimeout(function(){
      grecaptcha.render('g-recaptcha', {
        'sitekey' : '6Le9g3YUAAAAAA9c0JaayCxHw3R6wZ9RF4V3e53B'
      });
    }, 300);

    $('#form-standard').validate({
      rules: {
        'client-phone': {'required': true},
        'yourname': {'required': true},
      },
      messages: {
        'client-phone': 'Обязательно для заполнения',
        'yourname': 'Обязательно для заполнения',
      },
      showErrors: function (errorMap, errorList) {
        $.each(this.successList, function (index, value) {
          $(value).removeClass('error');
          $(value).parent().find('.form-control-error').remove();
        });

        return $.each(errorList, function (index, value) {
          var $el = $(value.element);
          $el.addClass('error');
          var msg = $el.parent().find('.form-control-error');
          if(msg.length){
            msg.html(value.message);
          }else{
            $el.after('<div style="margin-top: 10px;" class="form-control-error">'+value.message+'</div>');
          }
        });
      },
      submitHandler: function (form) {
        var $form = $(form);
        var data = $form.serialize();

        $.post($form.attr('action'), data)
          .done(function (res) {
            var $alert = $form.find('.form-message');
            if (res.result.errors.message) {
              $alertTemplate.find('p').text(res.result.errors.message);
            } else {
              $alertTemplate.find('p').text('Спасибо за Ваше обращение. В ближайшее время мы свяжемся с Вами!');

              //metrics and analytics
              var typeGoal = $('.mail-title-input').val() === 'Заказ: Аренда сервера' ? 'Dedic_Order' : 'Colocation_Order';
              window.yaCounter24402322.reachGoal(typeGoal);
              window.ga('send', 'event', 'order', typeGoal);

              $form.find('input, textarea, button').attr('disabled', true);
              setTimeout(function () {
                $('#form-standard').find('.close').click();
              }, 7000);
            }
            $alert.html($alertTemplate);
          });
        return false;
      }
    });

  })();

  /**
   * feedback-request form
   */
  (function () {
    if (window.location.pathname !== '/services/colocation' && window.location.pathname !== '/services/dedicated') return;

    var $alertTemplate = $('<div class="alert alert-warning alert-dismissible" role="alert">' +
      '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
      '<span aria-hidden="true">×</span>' +
      '</button>' +
      '<p></p>' +
      '</div>');

    $('#form-feedback').validate({
      rules: {
        'client-phone': {'required': true},
      },
      messages: {
        'client-phone': 'Обязательно для заполнения',
      },
      showErrors: function (errorMap, errorList) {
        $.each(this.successList, function (index, value) {
          $(value).removeClass('error');
          $(value).parent().find('.form-control-error').remove();
        });

        return $.each(errorList, function (index, value) {
          var $el = $(value.element);
          $el.addClass('error');
          var msg = $el.parent().find('.form-control-error');
          if(msg.length){
            msg.html(value.message);
          }else{
            $el.after('<div class="form-control-error">'+value.message+'</div>');
          }
        });
      },
      submitHandler: function (form) {
        var $form = $(form);
        var data = $form.serialize();

        $.post($form.attr('action'), data)
          .done(function (res) {
            var $alert = $form.find('.form-message');
            if (res.result.errors.message) {
              $alertTemplate.find('p').text(res.result.errors.message);
            } else {
              $alertTemplate.find('p').text('Спасибо за Ваше обращение. В ближайшее время мы свяжемся с Вами!');
              $form.find('input, textarea, button').attr('disabled', true);
            }
            $alert.html($alertTemplate);
          });
        return false;
      }
    });

  })();

  /**
   * form-certificate-order
   */
  (function () {
    if (window.location.pathname !== '/domain/certificate') return;

    $('.dropdown-toggle').dropdown();
    $('#form-certificate-order').validate({
      ignore: ".ignore",
      rules: {
        email: {required: true, email: true},
        name: 'required',
        hiddenRecaptcha: {
          required: function () {
            return grecaptcha.getResponse() === '';
          }
        }
      },
      messages: {
        email: {
          required: 'Обязательно для заполнения',
          email: 'Введите корректный email'
        },
        name: {
          required: 'Обязательно для заполнения'
        },
        hiddenRecaptcha: {
          required: 'Докажи, что не робот'
        }
      },
      submitHandler: function (form) {
        var data = $(form).serialize();

        $.post($(form).attr('action'), data, function(data){

          if (data.result.data.success) {
            // Обрабатываем полученные данные
            $('#order-certificate').modal('hide');
            if (data.result.data.msg) {
              $('#msgBox .content').text(data.result.data.msg);
              $('#msgBox').modal('show');
            }

            var purchase = {
              'id': data.result.data.ecommerce_data.order_id
            };
            var product = {
              'name': 'Свидетельство на домен',
              'category': 'Свидетельство на домен',
              'variant': data.result.data.ecommerce_data.domain
            };

            try {
              /**
               * Yandex.Metrika ecommerce
               */
              window.dataLayer.push({
                "ecommerce": {
                  "purchase": {
                    "actionField": purchase,
                    "products": [product]
                  }
                }
              });
            } catch (e) {
              console.warn(e);
            }

            try {
              /**
               * Google Analytics ecommerce
               */
              ga('ec:addProduct', product);
              ga('ec:setAction', 'purchase', purchase);
              ga("send", "event", "Ecommerce", "Service purchase completed");
            } catch (e) {
              console.warn(e);
            }

            $('#order-certificate').find('.close').click();
          } else {
            if(!$('#order-certificate label').is('#hiddenRecaptcha-error')){
              $('#order-certificate #hiddenRecaptcha').after('<label id="hiddenRecaptcha-error" class="error" for="hiddenRecaptcha"></label>')
            }
            $('#hiddenRecaptcha-error').html(data.result.errors.message)
          }
        });
        return false;
      }
    });

    $('#ordertext').popover({
      placement : 'top'
    });
    $('.order-certificate-button').on('click', function () {
      $('#order-certificate').modal('show');
    });

  })();

  /**
   * SSL form
   */
  (function () {
    if (window.location.pathname !== '/ssl') return;

    $(document).on('click', '.js-popup-ssl-show', function () {
      try {
        $('input#crt_id').val(this.id);
        $('#cart-total > span').text(getCartSumm());
        $('button#search').parent().next().find('.domain-ssl__table').empty();
        DomainsInCart(this.id);
      } catch (e) {
        console.log(e.message);
      }
    });

    function getCart() {
      return localStorage.getItem('domenus.cart');
    }

    function getCartSumm() {
      var total = 0;
      JSON.parse(getCart()).forEach(function(item) {
        var price = parseInt(item.price, 10),
          period = parseInt(item.period || 1, 10);
        total += parseInt(price * period, 10);
      });
      return total;
    }

    $(document).on('click', 'input[name=send-ssl]', function () {
      var id = $('input#crt_id').val();
      var o = $('a#'+id).parent().prev();
      var period = o.prev().find('input[name=count]').val();
      var iCart = JSON.parse(getCart());

      $('table.domain-ssl__table tbody tr.select').each(function(i, elem) {
        var exist = false;
        var domain = $(elem).find('.domain-ssl__name').text().trim();

        iCart.forEach(function(entry) {
          if(entry.crt_id == id && entry.domain == domain) {
            exist = true;
          }
        });

        if(!exist) {
          iCart.push({
            type: 'ssl',
            crt_id: id,
            min_period: period,//1,
            max_period: period,//3,
            period: period,//TODO: there are different period prices
            price: parseInt(o.text()),
            _name: o.parent().find('td.js-name-ssl').text(),
            name: o.parent().find('td.js-name-ssl').text() + ' для ' + domain,
            domain: domain
          });
        }
      });

      localStorage.setItem("domenus.cart", JSON.stringify(iCart));
      $(this).prop('disabled', false);
      location.pathname = '/cart';
    });

    //-> Поиск домена по Enter
    $(document).on('keypress', 'input[name="domain"]', function(e) {
      if (e.keyCode == 13) { $('button#search').click();}
    });

    //Поиск домена
    $(document).on('click', 'button#search', function () {
      var regexp = /^([a-zа-я0-9]{1})((\.[a-zа-я0-9-])|([a-zа-я0-9-]))*\.([a-zа-я]{2,6})(\/?)$/;
      var domain = $(this).prev().val().toLowerCase(); //.replace(/\s/g, '');
      if(domain == '') { alert('Поле не должно быть пустым.'); return (false); }
      var vdomain = domain.replace(/, /g, ':').replace(/,/g, ':').replace(/ /g, ':');
      var obj = $(this);
      vdomain.split(':').forEach(function(domain) {
        if(regexp.test(domain)) {
          tableSearchDomain(domain, obj);
        }
      });
    });

    // Показ таблицы выбора доменов
    function tableSearchDomain(domain, obj) {
      try {
        $('table.domain-ssl__table').find('.domain-ssl__name').each(function(k, val) {
          if($(val).text() == domain) { throw 'This domain exist'; }
        });
        $.ajax({
          type: 'post', url: '/ssl/addDomainSearchTable', data: { 'domain': domain }, dataType: 'json',
          success: function(result) {

            if(typeof result.cart.tld !== "undefined") {
              console.log(result.cart);
              var iCart = JSON.parse(getCart());
              iCart.push({
                tld: result.cart.tld,
                available : 'available',
                min_period: 1,
                max_period: 1,
                period: 1,
                price: result.cart.price,
                name: result.cart.name,
              });
              localStorage.setItem("domenus.cart", JSON.stringify(iCart));
            }

            obj = obj.parent().next().find('.domain-ssl__table');
            obj.append(result.html).hide().fadeIn();
            $(obj).find('tr').last().click();
          }
        });

      } catch (e) {
        console.log(e);
      }
    }


    // Домены из корзины
    function DomainsInCart(id) {

      var r = $('table.table-domain');
      r.parent().hide();
      $.ajax({
        type: 'post', url: '/ssl/searchDomainForSsl',
        data: { 'cart': getCart(), 'id': id }, dataType: 'json',
        success: function(result) {
          if(result.count > 0) {
            setTimeout(function () {
              r.empty();
              $(result.html).each(function () {
                r.append($(this).attr('row'));
              });
              r.parent().fadeIn();
            }, 200);
          }
        }
      });
    }

    $(document).on('click', 'a.products__buy', function (event) {
      event.preventDefault(); var t = -30;
      $("html, body").animate({ scrollTop: $('div.ssl-certificates').offset().top + t }, 500);
      $("table.table-ssl tbody tr").show();
      var x = $("table.table-ssl tbody tr i."+$(this).data('name')+":not(.domenus-small-chek)");
      x.parent().parent().fadeOut();
    });

    $(document).on('click', 'table.domain-ssl__table tbody tr', function (event) {
      event.preventDefault();
      var b = $('input[name="send-ssl"]');
      var selected = $('table.domain-ssl__table').find('tr.select').length;

      var prc = 0;
      if(selected > 0) {
        b.css('opacity', '1').prop('disabled', false);
        prc = $('a#' + $('input#crt_id').val()).parent().prev().find('span').text() * selected;
      }else{
        b.css('opacity', '0.4').prop('disabled', true);
      }

      $('#cart-total > span').empty().text(getCartSumm() + prc);
      var icart = JSON.parse(getCart());
      if(!$(this).hasClass("select")) {
        var domain = $(this).find('.domain-ssl__name').text().trim();
        icart.forEach(function(entry, key) {
          if(entry.crt_id == $('input#crt_id').val() && entry.domain == domain) {
            icart.splice(key, 1);
            localStorage.setItem("domenus.cart", JSON.stringify(icart));
            prc = 0;
            $('#cart-total > span').empty().text(getCartSumm() + prc);
          }
        });
      }

    });

  })();

})(jQuery);
